import axios from 'axios';
import { FinancialInterest } from '../types/nocodb';

const API_URL = import.meta.env.VITE_REACT_APP_NOCODB_API_URL;
const API_TOKEN = import.meta.env.VITE_REACT_APP_NOCODB_API_TOKEN;

const nocodbApi = axios.create({
  baseURL: API_URL,
  headers: {
    'xc-token': API_TOKEN
  }
});

export const TABLE_IDS = {
  USERS: 'm8sj23ouy6jfsnr',
  GENERAL_FEEDBACK: 'm4ep2wkxo1xoxs1',
  SOLUTION_FEEDBACK: 'mlthj26htea1pcd',
  SOLUTIONS: 'mj5e0isyx8poscz',
  INTEGRATION_FEEDBACK: 'mrb9y2e0h0etg7p',
  DESKTOP_FEEDBACK: 'mxvyxhmo76xx69k',
  FINANCIAL: 'meqbgspu70emn7t'
};

export const getGeneralFeedback = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.GENERAL_FEEDBACK}/records`
    );
    return response.data.list;
  } catch (error) {
    console.error('Error fetching general feedback:', error);
    throw error;
  }
};

export const getSolutionFeedback = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.SOLUTION_FEEDBACK}/records`
    );
    return response.data.list;
  } catch (error) {
    console.error('Error fetching solution feedback:', error);
    throw error;
  }
};

export const getDesktopFeedback = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.DESKTOP_FEEDBACK}/records`
    );
    return response.data.list;
  } catch (error) {
    console.error('Error fetching desktop feedback:', error);
    throw error;
  }
};

export const getIntegrationFeedback = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.INTEGRATION_FEEDBACK}/records`
    );
    return response.data.list;
  } catch (error) {
    console.error('Error fetching integration feedback:', error);
    throw error;
  }
};

export const getUserProgress = async () => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.USERS}/records`
    );
    return response.data.list;
  } catch (error) {
    console.error('Error fetching user progress:', error);
    throw error;
  }
};

export const getFinancialInterests = async (client: string) => {
  try {
    const response = await nocodbApi.get(
      `/api/v2/tables/${TABLE_IDS.FINANCIAL}/records`,
      {
        params: {
          where: `(Client,eq,${client})`
        }
      }
    );
    return response.data.list || [];
  } catch (error) {
    console.error('Error fetching financial interests:', error);
    throw error;
  }
};

export const updateFinancialInterests = async (data: FinancialInterest) => {
  try {
    if (data.Id) {
      // Update existing record
      const response = await nocodbApi.patch(
        `/api/v2/tables/${TABLE_IDS.FINANCIAL}/records`,
        [data]
      );
      return response.data;
    } else {
      // Create new record
      const response = await nocodbApi.post(
        `/api/v2/tables/${TABLE_IDS.FINANCIAL}/records`,
        data
      );
      return response.data;
    }
  } catch (error) {
    console.error('Error updating financial interests:', error);
    throw error;
  }
};