import { useState, useEffect } from 'react';
import { getIntegrationFeedback } from '../services/nocodbApi';
import { IntegrationFeedback } from '../types/nocodb';

interface IntegrationData {
  name: string;
  description: string;
  count: number;
  email: string;
}

interface UseIntegrationDataReturn {
  totalIntegrations: number;
  integrationCounts: { name: string; count: number }[];
  topRequested: IntegrationData[];
  loading: boolean;
  error: string | null;
}

export function useIntegrationData(): UseIntegrationDataReturn {
  const [totalIntegrations, setTotalIntegrations] = useState(0);
  const [integrationCounts, setIntegrationCounts] = useState<{ name: string; count: number }[]>([]);
  const [topRequested, setTopRequested] = useState<IntegrationData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const feedback = await getIntegrationFeedback();
        
        // Process integration data
        const integrations = new Map<string, { count: number; descriptions: string[]; emails: string[] }>();
        let total = 0;

        feedback.forEach(entry => {
          // Process each integration field (Integration1 through Integration10)
          for (let i = 1; i <= 10; i++) {
            const key = `Integration${i}` as keyof IntegrationFeedback;
            const value = entry[key];
            
            if (value && typeof value === 'string' && value.trim() !== '') {
              total++;
              const [name, description] = value.split(':').map(s => s.trim());
              
              if (!integrations.has(name)) {
                integrations.set(name, { count: 0, descriptions: [], emails: [] });
              }
              
              const data = integrations.get(name)!;
              data.count++;
              if (description) data.descriptions.push(description);
              if (entry.Client) data.emails.push(entry.Client);
            }
          }
        });

        // Convert to arrays and sort
        const countsList = Array.from(integrations.entries()).map(([name, data]) => ({
          name,
          count: data.count
        })).sort((a, b) => b.count - a.count);

        const requestedList = Array.from(integrations.entries()).map(([name, data]) => ({
          name,
          description: data.descriptions[0] || '',
          count: data.count,
          email: data.emails[0] || ''
        })).sort((a, b) => b.count - a.count);

        setTotalIntegrations(total);
        setIntegrationCounts(countsList);
        setTopRequested(requestedList);
      } catch (err) {
        setError('Failed to load integration data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    totalIntegrations,
    integrationCounts,
    topRequested,
    loading,
    error
  };
}