import { SOLUTION_SECTIONS, DESKTOP_SECTIONS, TOTAL_SOLUTION_SECTIONS, TOTAL_DESKTOP_SECTIONS } from '../constants/sections';
import { UserProgress } from '../types/nocodb';

interface SectionInfo {
  solution: string;
  section: string;
}

export const getSectionInfo = (sectionId: string): SectionInfo | null => {
  for (const [solution, data] of Object.entries(SOLUTION_SECTIONS)) {
    if (sectionId in data.sections) {
      return {
        solution,
        section: data.sections[sectionId]
      };
    }
  }
  return null;
};

export const getDesktopSectionInfo = (sectionId: string): SectionInfo | null => {
  for (const [category, data] of Object.entries(DESKTOP_SECTIONS)) {
    if (sectionId in data.sections) {
      return {
        solution: category,
        section: data.sections[sectionId]
      };
    }
  }
  return null;
};

export const analyzeSectionCompletion = (user: UserProgress): UserProgress => {
  const completedSections = user.solutions_checks ? user.solutions_checks.split(',').filter(Boolean) : [];
  const allSectionIds = getAllSectionIds(SOLUTION_SECTIONS);
  const missingSections = allSectionIds.filter(id => !completedSections.includes(id));

  return {
    ...user,
    completedSections,
    missingSections,
    solutions_progress: calculateCompletionPercentage(completedSections.length, TOTAL_SOLUTION_SECTIONS)
  };
};

export const analyzeDesktopCompletion = (user: UserProgress): UserProgress => {
  const completedSections = user.desktop_checks ? user.desktop_checks.split(',').filter(Boolean) : [];
  const allSectionIds = getAllSectionIds(DESKTOP_SECTIONS);
  const missingSections = allSectionIds.filter(id => !completedSections.includes(id));
  const totalSections = allSectionIds.length; // Use actual count instead of constant

  return {
    ...user,
    completedDesktopSections: completedSections,
    missingDesktopSections: missingSections,
    desktop_progress: calculateCompletionPercentage(completedSections.length, totalSections)
  };
};

const getAllSectionIds = (sections: typeof SOLUTION_SECTIONS | typeof DESKTOP_SECTIONS): string[] => {
  const ids: string[] = [];
  Object.values(sections).forEach(data => {
    Object.keys(data.sections).forEach(id => ids.push(id));
  });
  return ids;
};

const calculateCompletionPercentage = (completed: number, total: number): number => {
  if (total === 0) return 0;
  return Math.min(100, Math.round((completed / total) * 100));
};