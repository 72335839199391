import React, { useState } from 'react';
import { X } from 'lucide-react';
import { CompanyInterests } from '../../types/nocodb';
import { SOLUTION_SECTIONS, DESKTOP_SECTIONS } from '../../constants/sections';

interface CompanyInterestModalProps {
  companyName: string;
  initialInterests: CompanyInterests;
  onClose: () => void;
  onSave: (
    companyName: string,
    solutions: string,
    desktop: string,
    solutionLicenses: { [key: string]: number },
    desktopLicenses: { [key: string]: number }
  ) => void;
}

export function CompanyInterestModal({ 
  companyName, 
  initialInterests, 
  onClose, 
  onSave 
}: CompanyInterestModalProps) {
  const [solutionLicenses, setSolutionLicenses] = useState<{ [key: string]: number }>(() => {
    const licenses = initialInterests.solutionLicenses || {};
    return Object.keys(SOLUTION_SECTIONS).reduce((acc, name) => {
      acc[name] = licenses[name] || 0;
      return acc;
    }, {} as { [key: string]: number });
  });

  const [desktopLicenses, setDesktopLicenses] = useState<{ [key: string]: number }>(() => {
    const licenses = initialInterests.desktopLicenses || {};
    return Object.keys(DESKTOP_SECTIONS).reduce((acc, name) => {
      acc[name] = licenses[name] || 0;
      return acc;
    }, {} as { [key: string]: number });
  });

  const handleLicenseChange = (
    type: 'solution' | 'desktop',
    name: string,
    value: string
  ) => {
    const numValue = value === '' ? 0 : parseInt(value, 10);
    if (numValue < 0) return;

    if (type === 'solution') {
      setSolutionLicenses(prev => ({
        ...prev,
        [name]: numValue
      }));
    } else {
      setDesktopLicenses(prev => ({
        ...prev,
        [name]: numValue
      }));
    }
  };

  const handleSaveClick = () => {
    const solutionsStr = Object.entries(solutionLicenses)
      .filter(([, count]) => count > 0)
      .map(([name, count]) => `${name}:${count}`)
      .join(',');

    const desktopStr = Object.entries(desktopLicenses)
      .filter(([, count]) => count > 0)
      .map(([name, count]) => `${name}:${count}`)
      .join(',');

    onSave(companyName, solutionsStr, desktopStr, solutionLicenses, desktopLicenses);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl max-w-4xl w-full max-h-[80vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200 flex items-center justify-between">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Product Interest</h2>
            <p className="text-sm text-gray-500">{companyName}</p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>
        
        <div className="p-6 overflow-y-auto max-h-[calc(80vh-129px)]">
          <div className="grid grid-cols-2 gap-8">
            {/* Solutions */}
            <div>
              <h3 className="font-medium text-gray-900 mb-4">Solutions</h3>
              <div className="space-y-4">
                {Object.entries(SOLUTION_SECTIONS).map(([name]) => (
                  <div key={name} className="flex items-center space-x-4">
                    <label className="flex-1 text-sm text-gray-600">{name}</label>
                    <input
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={solutionLicenses[name] || ''}
                      onChange={(e) => handleLicenseChange('solution', name, e.target.value)}
                      className="w-24 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Desktop Features */}
            <div>
              <h3 className="font-medium text-gray-900 mb-4">Desktop Features</h3>
              <div className="space-y-4">
                {Object.entries(DESKTOP_SECTIONS).map(([name]) => (
                  <div key={name} className="flex items-center space-x-4">
                    <label className="flex-1 text-sm text-gray-600">{name}</label>
                    <input
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={desktopLicenses[name] || ''}
                      onChange={(e) => handleLicenseChange('desktop', name, e.target.value)}
                      className="w-24 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="p-6 border-t border-gray-200 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveClick}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}