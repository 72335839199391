import React from 'react';
import { X, Link as LinkIcon } from 'lucide-react';
import { IntegrationFeedback, UserProgress } from '../../types/nocodb';
import { getIntegrationFields } from '../../utils/integrationUtils';

interface IntegrationModalProps {
  email: string;
  user: UserProgress;
  integrations: IntegrationFeedback[];
  onClose: () => void;
}

export const IntegrationModal: React.FC<IntegrationModalProps> = ({ email, user, integrations, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[80vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200 flex items-center justify-between">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Integration Details</h2>
            <div className="text-sm text-gray-500">
              <div className="font-medium">{user?.frontegg_clientname || email}</div>
              <div>{email}</div>
            </div>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>
        
        <div className="p-6 overflow-y-auto max-h-[calc(80vh-129px)]">
          {integrations.map((feedback, index) => {
            const fields = getIntegrationFields(feedback);
            if (fields.length === 0) return null;
            
            return (
              <div key={index} className="mb-6 last:mb-0">
                <div className="flex items-center space-x-2 mb-4">
                  <LinkIcon className="w-5 h-5 text-blue-500" />
                  <h3 className="font-medium text-gray-900">Integration Set {index + 1}</h3>
                </div>
                <div className="grid grid-cols-1 gap-4">
                  {fields.map(({ key, value }, fieldIndex) => (
                    <div key={fieldIndex} className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-sm font-medium text-gray-700">{key}</p>
                      <p className="text-sm text-gray-600 mt-1">{value}</p>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};