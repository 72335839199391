import React from 'react';
import { Building2, ChevronRight } from 'lucide-react';
import { CompanyInterests } from '../../types/nocodb';

interface CompanyListProps {
  companies: { name: string; interests: CompanyInterests }[];
  onSelectCompany: (name: string) => void;
}

export function CompanyList({ companies, onSelectCompany }: CompanyListProps) {
  const countInterests = (interests: CompanyInterests) => {
    const solutionCount = interests.solutions ? interests.solutions.split(',').length : 0;
    const desktopCount = interests.desktop ? interests.desktop.split(',').length : 0;
    return { solutionCount, desktopCount };
  };

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Companies</h2>
      </div>
      
      <ul className="divide-y divide-gray-200">
        {companies.map((company) => {
          const { solutionCount, desktopCount } = countInterests(company.interests);
          return (
            <li key={company.name}>
              <button
                onClick={() => onSelectCompany(company.name)}
                className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50 transition-colors"
              >
                <div className="flex items-center space-x-3">
                  <div className="flex-shrink-0">
                    <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                      <Building2 className="h-5 w-5 text-blue-500" />
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="text-sm font-medium text-gray-900">
                      {company.name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {solutionCount} solutions, {desktopCount} features
                    </div>
                  </div>
                </div>
                <ChevronRight className="w-5 h-5 text-gray-400" />
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}