import React from 'react';
import { Link2, Wrench, HelpCircle } from 'lucide-react';
import { useIntegrationData } from '../hooks/useIntegrationData';
import { Tooltip } from './Tooltip';

export function IntegrationOverview() {
  const { totalIntegrations, integrationCounts, topRequested, loading, error } = useIntegrationData();

  if (loading) return <div className="animate-pulse">Loading...</div>;
  if (error) return <div className="text-red-500">Error loading integration data</div>;

  return (
    <>
      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="space-y-4">
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <Link2 className="w-5 h-5 text-blue-600" />
              <h3 className="font-medium">Integrations</h3>
            </div>
            <p className="text-2xl font-semibold">{totalIntegrations}</p>
          </div>
          
          <div className="pt-4 border-t">
            <div className="flex items-center space-x-2 mb-2">
              <Wrench className="w-5 h-5 text-purple-600" />
              <h3 className="font-medium">Unique Tools</h3>
            </div>
            <p className="text-2xl font-semibold">{integrationCounts.length}</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 shadow-sm col-span-2">
        <h3 className="text-lg font-medium mb-4">Top Requested</h3>
        <div className="space-y-4 max-h-[300px] overflow-y-auto pr-2">
          {topRequested.map((integration, index) => (
            <div key={index} className="flex items-center justify-between">
              <div>
                <p className="font-medium">{integration.name}</p>
                <div className="flex items-center space-x-2">
                  <p className="text-sm text-gray-500 truncate max-w-[200px]">
                    {integration.description}
                  </p>
                  {integration.description.length > 30 && (
                    <Tooltip content={integration.description}>
                      <HelpCircle className="w-4 h-4 text-gray-400 cursor-help" />
                    </Tooltip>
                  )}
                </div>
              </div>
              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                {integration.count}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 shadow-sm">
        <h3 className="text-lg font-medium mb-4">Latest Ideas</h3>
        <div className="space-y-4 max-h-[300px] overflow-y-auto pr-2">
          {topRequested.map((integration, index) => (
            <div key={index} className="space-y-1">
              <p className="text-sm text-gray-500">{integration.email}</p>
              <div className="flex items-center space-x-2">
                <p className="text-sm truncate max-w-[200px]">{integration.description}</p>
                {integration.description.length > 30 && (
                  <Tooltip content={integration.description}>
                    <HelpCircle className="w-4 h-4 text-gray-400 cursor-help" />
                  </Tooltip>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}