import React, { useEffect, useState } from 'react';
import { Star, CheckCircle, DollarSign, MessageCircle, Users } from 'lucide-react';
import { SolutionFeedback, SolutionMetrics, DesktopMetrics } from '../types/nocodb';
import { getSolutionFeedback, getDesktopFeedback } from '../services/nocodbApi';

const ALL_SOLUTIONS = [
  'Automation-As-A-Service',
  'Expert Path',
  'Master Fix Database',
  'Onboarding Navigator',
  'Outage Hub',
  'Project Path',
  'Robotic Process Automation',
  'Workflow Orchestrator'
];

const DESKTOP_INTEGRATIONS = [
  { name: 'Troubleshoot', field: 'Troubleshoot' },
  { name: 'Applications', field: 'Applications' },
  { name: 'Knowledge', field: 'Knowledge' },
  { name: 'Automation', field: 'Automation' },
  { name: 'Status Page', field: 'Status Page' },
  { name: 'Enterprise Search', field: 'Search' },
  { name: 'Onboarding', field: 'Onboarding' }
];

export function FeedbackSummary() {
  const [metrics, setMetrics] = useState<SolutionMetrics[]>([]);
  const [desktopMetrics, setDesktopMetrics] = useState<DesktopMetrics[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [solutionFeedback, desktopFeedback] = await Promise.all([
          getSolutionFeedback(),
          getDesktopFeedback()
        ]);
        
        const solutionMetrics = calculateSolutionMetrics(solutionFeedback);
        const calculatedDesktopMetrics = calculateDesktopMetrics(desktopFeedback);
        
        setMetrics(solutionMetrics);
        setDesktopMetrics(calculatedDesktopMetrics);
      } catch (error) {
        console.error('Error fetching feedback:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateSolutionMetrics = (feedback: SolutionFeedback[]): SolutionMetrics[] => {
    const solutionMap = new Map<string, SolutionFeedback[]>();
    
    ALL_SOLUTIONS.forEach(solution => {
      solutionMap.set(solution, []);
    });

    feedback.forEach(item => {
      const solution = item.Solution;
      if (solutionMap.has(solution)) {
        solutionMap.get(solution)?.push(item);
      }
    });

    return Array.from(solutionMap.entries()).map(([name, items]) => ({
      name,
      averageRating: items.length > 0 ? items.reduce((acc, curr) => acc + curr.Rating, 0) / items.length : 0,
      totalResponses: items.length,
      challengesAddressed: items.filter(item => item.Challenges === 'Yes').length,
      willingToPay: items.filter(item => item.Paying === 'Yes').length,
      followUpRequests: items.filter(item => item.Thoughts && item.Thoughts.trim() !== '').length
    }));
  };

  const calculateDesktopMetrics = (feedback: any[]): DesktopMetrics[] => {
    // Calculate overall product metrics first
    const totalResponses = feedback.length;
    const challengesAddressed = feedback.filter(item => item.Challenges === 'Yes').length;
    const willingToPay = feedback.filter(item => item.Payment === 'Yes').length;
    const followUpRequests = feedback.filter(item => item.Call === 'Yes').length;

    // Calculate individual ratings for each integration
    return DESKTOP_INTEGRATIONS.map(integration => {
      const relevantFeedback = feedback.filter(item => 
        item[integration.field] !== null && 
        item[integration.field] !== undefined
      );
      
      return {
        name: integration.name,
        averageRating: relevantFeedback.length > 0 
          ? relevantFeedback.reduce((acc, curr) => acc + Number(curr[integration.field]), 0) / relevantFeedback.length 
          : 0,
        totalResponses,
        challengesAddressed,
        willingToPay,
        followUpRequests
      };
    }).sort((a, b) => b.averageRating - a.averageRating);
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl p-6 shadow-sm animate-pulse">
        <div className="h-32 bg-gray-200 rounded-lg"></div>
      </div>
    );
  }

  const sortedMetrics = metrics.sort((a, b) => b.averageRating - a.averageRating);
  const totalSolutionFollowUps = metrics.reduce((acc, curr) => acc + curr.followUpRequests, 0);
  const totalDesktopResponses = desktopMetrics.length > 0 ? desktopMetrics[0].totalResponses : 0;
  const totalDesktopChallenges = desktopMetrics.length > 0 ? desktopMetrics[0].challengesAddressed : 0;
  const totalDesktopPayment = desktopMetrics.length > 0 ? desktopMetrics[0].willingToPay : 0;
  const totalDesktopFollowUps = desktopMetrics.length > 0 ? desktopMetrics[0].followUpRequests : 0;

  return (
    <div className="space-y-8">
      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold">Solutions Feedback</h2>
          <div className="flex items-center space-x-6">
            <div className="text-sm text-gray-500">
              Total Responses: {metrics.reduce((acc, curr) => acc + curr.totalResponses, 0)}
            </div>
            <div className="flex items-center space-x-2 bg-blue-50 px-4 py-2 rounded-lg">
              <Users className="w-4 h-4 text-blue-500" />
              <span className="text-sm font-medium text-blue-700">
                {totalSolutionFollowUps} Follow-ups
              </span>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-8 gap-3">
          {sortedMetrics.map((solution) => (
            <div 
              key={solution.name} 
              className={`${
                solution.totalResponses === 0 ? 'bg-gray-50' : 'bg-blue-50'
              } rounded-lg p-2.5`}
            >
              <div className="flex flex-col items-center mb-2">
                <div className="h-12 flex items-center text-center">
                  <h3 className="font-medium text-gray-900 text-sm leading-tight">
                    {solution.name}
                  </h3>
                </div>
                <div className={`flex items-center px-2 py-1 rounded-full ${
                  solution.totalResponses === 0 ? 'bg-gray-100' : 'bg-white'
                }`}>
                  <Star className={`w-4 h-4 mr-1 ${
                    solution.totalResponses === 0 ? 'text-gray-400' : 'text-yellow-400 fill-current'
                  }`} />
                  <span className="text-sm font-semibold">
                    {solution.averageRating.toFixed(1)}
                  </span>
                </div>
              </div>

              <div className="space-y-1">
                <div className="flex items-center justify-between text-xs">
                  <div className="flex items-center">
                    <CheckCircle className={`w-3 h-3 mr-1 ${
                      solution.totalResponses === 0 ? 'text-gray-400' : 'text-green-600'
                    }`} />
                    <span>{solution.totalResponses === 0 ? '0' : 
                      Math.round((solution.challengesAddressed / solution.totalResponses) * 100)}%</span>
                  </div>
                  <span className="text-gray-500 ml-1">Pain</span>
                </div>

                <div className="flex items-center justify-between text-xs">
                  <div className="flex items-center">
                    <DollarSign className={`w-3 h-3 mr-1 ${
                      solution.totalResponses === 0 ? 'text-gray-400' : 'text-blue-600'
                    }`} />
                    <span>{solution.totalResponses === 0 ? '0' : 
                      Math.round((solution.willingToPay / solution.totalResponses) * 100)}%</span>
                  </div>
                  <span className="text-gray-500 ml-1">Pay</span>
                </div>

                <div className="flex items-center justify-between text-xs">
                  <div className="flex items-center">
                    <MessageCircle className={`w-3 h-3 mr-1 ${
                      solution.totalResponses === 0 ? 'text-gray-400' : 'text-purple-600'
                    }`} />
                    <span>{solution.followUpRequests}</span>
                  </div>
                  <span className="text-gray-500 ml-1">Meet</span>
                </div>
              </div>

              {solution.totalResponses === 0 && (
                <div className="text-xs text-gray-500 mt-2 text-center italic">
                  No data
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold">Desktop Feedback</h2>
          <div className="flex items-center space-x-6">
            <div className="text-sm text-gray-500">
              Total Responses: {totalDesktopResponses}
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2 bg-green-50 px-3 py-1.5 rounded-lg">
                <CheckCircle className="w-4 h-4 text-green-500" />
                <span className="text-sm font-medium text-green-700">
                  {Math.round((totalDesktopChallenges / totalDesktopResponses) * 100)}% Pain
                </span>
              </div>
              <div className="flex items-center space-x-2 bg-blue-50 px-3 py-1.5 rounded-lg">
                <DollarSign className="w-4 h-4 text-blue-500" />
                <span className="text-sm font-medium text-blue-700">
                  {Math.round((totalDesktopPayment / totalDesktopResponses) * 100)}% Pay
                </span>
              </div>
              <div className="flex items-center space-x-2 bg-purple-50 px-3 py-1.5 rounded-lg">
                <MessageCircle className="w-4 h-4 text-purple-500" />
                <span className="text-sm font-medium text-purple-700">
                  {totalDesktopFollowUps} Meet
                </span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-7 gap-3">
          {desktopMetrics.map((integration) => (
            <div 
              key={integration.name} 
              className={`${
                integration.totalResponses === 0 ? 'bg-gray-50' : 'bg-purple-50'
              } rounded-lg p-2.5`}
            >
              <div className="flex flex-col items-center">
                <div className="h-12 flex items-center text-center">
                  <h3 className="font-medium text-gray-900 text-sm leading-tight">
                    {integration.name}
                  </h3>
                </div>
                <div className={`flex items-center px-2 py-1 rounded-full ${
                  integration.totalResponses === 0 ? 'bg-gray-100' : 'bg-white'
                }`}>
                  <Star className={`w-4 h-4 mr-1 ${
                    integration.totalResponses === 0 ? 'text-gray-400' : 'text-yellow-400 fill-current'
                  }`} />
                  <span className="text-sm font-semibold">
                    {integration.averageRating.toFixed(1)}
                  </span>
                </div>
              </div>

              {integration.totalResponses === 0 && (
                <div className="text-xs text-gray-500 mt-2 text-center italic">
                  No data
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}