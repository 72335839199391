import React, { useState, useEffect } from 'react';
import { CompanyList } from './CompanyList';
import { CompanyInterestModal } from './CompanyInterestModal';
import { RevenueProjections } from './RevenueProjections';
import { ProductMatrix } from './ProductMatrix';
import { getUserProgress, getFinancialInterests, updateFinancialInterests } from '../../services/nocodbApi';
import { CompanyInterests } from '../../types/nocodb';

export function FinancialPage() {
  const [companies, setCompanies] = useState<Array<{ name: string; interests: CompanyInterests }>>([]);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const users = await getUserProgress();
      const uniqueCompanies = [...new Set(users.map(user => user.frontegg_accountname))];

      const companyData = await Promise.all(
        uniqueCompanies.map(async (companyName) => {
          const interests = await getFinancialInterests(companyName);
          const existingData = interests[0] || { Solutions: '', Desktop: '' };

          const solutionLicenses = parseSolutionString(existingData.Solutions);
          const desktopLicenses = parseSolutionString(existingData.Desktop);

          return {
            name: companyName,
            interests: {
              solutions: existingData.Solutions || '',
              desktop: existingData.Desktop || '',
              solutionLicenses,
              desktopLicenses
            }
          };
        })
      );

      setCompanies(companyData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const parseSolutionString = (str: string) => {
    if (!str) return {};
    return str.split(',').reduce((acc: { [key: string]: number }, item) => {
      if (!item) return acc;
      const [name, count] = item.split(':');
      if (name && count) {
        acc[name.trim()] = parseInt(count);
      }
      return acc;
    }, {});
  };

  const handleSave = async (
    companyName: string,
    solutions: string,
    desktop: string,
    solutionLicenses: { [key: string]: number },
    desktopLicenses: { [key: string]: number }
  ) => {
    try {
      const existingData = (await getFinancialInterests(companyName))[0];

      const data = {
        Id: existingData?.Id,
        Client: companyName,
        Solutions: solutions,
        Desktop: desktop
      };

      await updateFinancialInterests(data);
      await fetchData();
      setSelectedCompany(null);
    } catch (error) {
      console.error('Error saving interests:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex-1 p-8">
        <div className="max-w-7xl mx-auto">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-8"></div>
            <div className="grid grid-cols-2 gap-6">
              <div className="h-96 bg-gray-200 rounded"></div>
              <div className="h-96 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">Financial Projections</h1>
          <p className="text-gray-500">Manage pricing and view revenue projections based on client interest</p>
        </div>

        <div className="mb-8">
          <RevenueProjections companies={companies} />
        </div>

        <div className="grid grid-cols-2 gap-6">
          <CompanyList
            companies={companies}
            onSelectCompany={setSelectedCompany}
          />
          <ProductMatrix
            companies={companies}
          />
        </div>

        {selectedCompany && (
          <CompanyInterestModal
            companyName={selectedCompany}
            initialInterests={companies.find(c => c.name === selectedCompany)?.interests || {
              solutions: '',
              desktop: '',
              solutionLicenses: {},
              desktopLicenses: {}
            }}
            onClose={() => setSelectedCompany(null)}
            onSave={handleSave}
          />
        )}
      </div>
    </div>
  );
}