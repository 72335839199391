import React, { useState, useEffect } from 'react';
import { User, ChevronRight, Building2 } from 'lucide-react';
import { UserProgress } from '../../types/nocodb';
import { SortHeader } from '../SortHeader';
import { ListPagination } from '../ListPagination';

interface SolutionsListProps {
  users: UserProgress[];
  onSelectUser: (user: UserProgress) => void;
}

const ITEMS_PER_PAGE = 10;

export const SolutionsList: React.FC<SolutionsListProps> = ({ users: initialUsers, onSelectUser }) => {
  const [users, setUsers] = useState(initialUsers);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState<{ key: string; direction: 'asc' | 'desc' }>({
    key: 'progress',
    direction: 'desc'
  });

  useEffect(() => {
    setUsers(initialUsers);
    setCurrentPage(1);
  }, [initialUsers]);

  const handleSort = (key: string) => {
    setSort(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'desc' ? 'asc' : 'desc'
    }));

    const sortedUsers = [...users].sort((a, b) => {
      let comparison = 0;
      if (key === 'progress') {
        comparison = a.solutions_progress - b.solutions_progress;
      } else if (key === 'company') {
        comparison = a.frontegg_accountname.localeCompare(b.frontegg_accountname);
      }
      return sort.direction === 'asc' ? comparison : -comparison;
    });
    setUsers(sortedUsers);
  };

  useEffect(() => {
    handleSort('progress');
  }, []); // Initial sort by progress

  const totalPages = Math.ceil(users.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedUsers = users.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                User
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <SortHeader
                  label="Company"
                  sortKey="company"
                  currentSort={sort}
                  onSort={handleSort}
                />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <SortHeader
                  label="Progress"
                  sortKey="progress"
                  currentSort={sort}
                  onSort={handleSort}
                />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Sections Completed
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Sections Remaining
              </th>
              <th className="relative px-6 py-3">
                <span className="sr-only">View Details</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {paginatedUsers.map((user) => (
              <tr
                key={user.frontegg_userid}
                className="hover:bg-gray-50 cursor-pointer"
                onClick={() => onSelectUser(user)}
              >
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                        <User className="h-5 w-5 text-blue-500" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {user.frontegg_clientname}
                      </div>
                      <div className="text-sm text-gray-500">
                        {user.frontegg_email}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center space-x-2">
                    <Building2 className="w-4 h-4 text-gray-400" />
                    <span className="text-sm text-gray-900">{user.frontegg_accountname}</span>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mr-2">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${user.solutions_progress}%` }}
                      />
                    </div>
                    <span className="text-sm text-gray-900">
                      {user.solutions_progress}%
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {user.completedSections?.length || 0}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {user.missingSections?.length || 0}
                </td>
                <td className="px-6 py-4 text-right text-sm font-medium">
                  <ChevronRight className="w-5 h-5 text-gray-400" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {totalPages > 1 && (
        <ListPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};