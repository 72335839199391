import React from 'react';
import { User, ChevronRight } from 'lucide-react';
import { IntegrationFeedback, UserProgress } from '../../types/nocodb';
import { countIntegrations } from '../../utils/integrationUtils';

interface IntegrationsListProps {
  integrations: { [key: string]: IntegrationFeedback[] };
  users: { [key: string]: UserProgress };
  onSelectEmail: (email: string) => void;
}

export const IntegrationsList: React.FC<IntegrationsListProps> = ({ integrations, users, onSelectEmail }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <ul className="divide-y divide-gray-200">
        {Object.entries(integrations).map(([email, feedbacks]) => {
          const integrationCount = countIntegrations(feedbacks);
          const user = users[email];
          const displayName = user?.frontegg_clientname || email;
          
          return (
            <li key={email}>
              <button
                onClick={() => onSelectEmail(email)}
                className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50 transition-colors"
              >
                <div className="flex items-center space-x-3">
                  <div className="flex-shrink-0">
                    <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                      <User className="h-5 h-5 text-blue-500" />
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="text-sm font-medium text-gray-900">
                      {displayName}
                    </div>
                    <div className="text-sm text-gray-500">
                      {email}
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="text-sm text-right">
                    <div className="font-medium text-gray-900">
                      {integrationCount} integration{integrationCount !== 1 ? 's' : ''}
                    </div>
                    <div className="text-gray-500">requested</div>
                  </div>
                  <ChevronRight className="w-5 h-5 text-gray-400" />
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};