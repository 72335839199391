import React from 'react';
import { Trophy, ArrowUp, ArrowDown, Building2 } from 'lucide-react';
import { getUserProgress } from '../services/nocodbApi';
import { UserProgress } from '../types/nocodb';
import { useState, useEffect } from 'react';

export function TopUsers() {
  const [users, setUsers] = useState<UserProgress[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const allUsers = await getUserProgress();
        const sortedUsers = allUsers
          .sort((a, b) => {
            const aTotal = (a.solutions_progress + a.desktop_progress) / 2;
            const bTotal = (b.solutions_progress + b.desktop_progress) / 2;
            return bTotal - aTotal;
          })
          .slice(0, 5);
        setUsers(sortedUsers);
      } catch (error) {
        console.error('Error fetching top users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return (
      <div className="bg-white rounded-xl p-6 shadow-sm h-[300px] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl p-6 shadow-sm">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-2">
          <Trophy className="w-5 h-5 text-yellow-500" />
          <h2 className="text-lg font-semibold">Top Users</h2>
        </div>
      </div>

      <div className="space-y-4">
        {users.map((user, index) => {
          const solutionsHigher = user.solutions_progress > user.desktop_progress;

          return (
            <div key={user.frontegg_userid} className="flex items-center space-x-4">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-50 rounded-full flex items-center justify-center">
                <span className="text-blue-600 font-semibold">{index + 1}</span>
              </div>
              
              <div className="flex-grow">
                <div className="mb-1">
                  <span className="font-medium text-gray-900">{user.frontegg_clientname}</span>
                  <div className="flex items-center text-sm text-gray-500">
                    <Building2 className="w-4 h-4 mr-1" />
                    <span>{user.frontegg_accountname}</span>
                  </div>
                </div>
                
                <div className="grid grid-cols-2 gap-2">
                  <div className="flex items-center space-x-1">
                    {solutionsHigher ? (
                      <ArrowUp className="w-4 h-4 text-green-500" />
                    ) : (
                      <ArrowDown className="w-4 h-4 text-red-500" />
                    )}
                    <span className="text-sm text-gray-600">
                      Solutions: {user.solutions_progress}%
                    </span>
                  </div>
                  <div className="flex items-center space-x-1">
                    {!solutionsHigher ? (
                      <ArrowUp className="w-4 h-4 text-green-500" />
                    ) : (
                      <ArrowDown className="w-4 h-4 text-red-500" />
                    )}
                    <span className="text-sm text-gray-600">
                      Desktop: {user.desktop_progress}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}