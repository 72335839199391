import React, { useState } from 'react';
import { User, ChevronRight, Building2 } from 'lucide-react';
import { FeedbackSummary } from '../../types/feedback';
import { UserProgress } from '../../types/nocodb';
import { SortHeader } from '../SortHeader';

interface FeedbackListProps {
  feedback: FeedbackSummary[];
  users: { [key: string]: UserProgress };
  onSelectEmail: (email: string) => void;
}

export const FeedbackList: React.FC<FeedbackListProps> = ({ feedback, users, onSelectEmail }) => {
  const [sort, setSort] = useState<{ key: string; direction: 'asc' | 'desc' }>({
    key: 'company',
    direction: 'asc'
  });

  const sortedFeedback = [...feedback].sort((a, b) => {
    const userA = users[a.email];
    const userB = users[b.email];

    if (sort.key === 'company') {
      const companyA = userA?.frontegg_accountname || 'Unknown';
      const companyB = userB?.frontegg_accountname || 'Unknown';
      return sort.direction === 'asc' 
        ? companyA.localeCompare(companyB)
        : companyB.localeCompare(companyA);
    }

    if (sort.key === 'user') {
      const nameA = userA?.frontegg_clientname || a.email;
      const nameB = userB?.frontegg_clientname || b.email;
      return sort.direction === 'asc'
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    }

    if (sort.key === 'responses') {
      const countA = a.generalFeedback.length + a.solutionFeedback.length + a.desktopFeedback.length;
      const countB = b.generalFeedback.length + b.solutionFeedback.length + b.desktopFeedback.length;
      return sort.direction === 'asc' ? countA - countB : countB - countA;
    }

    return 0;
  });

  const handleSort = (key: string) => {
    setSort(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <SortHeader
                  label="User"
                  sortKey="user"
                  currentSort={sort}
                  onSort={handleSort}
                />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <SortHeader
                  label="Company"
                  sortKey="company"
                  currentSort={sort}
                  onSort={handleSort}
                />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <SortHeader
                  label="Responses"
                  sortKey="responses"
                  currentSort={sort}
                  onSort={handleSort}
                />
              </th>
              <th className="relative px-6 py-3">
                <span className="sr-only">View Details</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedFeedback.map((item) => {
              const user = users[item.email];
              const totalFeedback = 
                item.generalFeedback.length + 
                item.solutionFeedback.length + 
                item.desktopFeedback.length;
              
              return (
                <tr
                  key={item.email}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => onSelectEmail(item.email)}
                >
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                          <User className="h-5 w-5 text-blue-500" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {user?.frontegg_clientname || 'Unknown User'}
                        </div>
                        <div className="text-sm text-gray-500">
                          {item.email}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center space-x-2">
                      <Building2 className="w-4 h-4 text-gray-400" />
                      <span className="text-sm text-gray-900">
                        {user?.frontegg_accountname || 'Unknown Company'}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {totalFeedback} responses
                  </td>
                  <td className="px-6 py-4 text-right text-sm font-medium">
                    <ChevronRight className="w-5 h-5 text-gray-400" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};