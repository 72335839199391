import React from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';

interface SortHeaderProps {
  label: string;
  sortKey: string;
  currentSort: { key: string; direction: 'asc' | 'desc' };
  onSort: (key: string) => void;
}

export function SortHeader({ label, sortKey, currentSort, onSort }: SortHeaderProps) {
  const isActive = currentSort.key === sortKey;

  return (
    <div
      onClick={() => onSort(sortKey)}
      className="flex items-center space-x-1 cursor-pointer group"
    >
      <span>{label}</span>
      <div className={`${isActive ? 'text-gray-600' : 'text-gray-400'} group-hover:text-gray-600`}>
        {isActive && currentSort.direction === 'asc' ? (
          <ChevronUp className="w-4 h-4" />
        ) : (
          <ChevronDown className="w-4 h-4" />
        )}
      </div>
    </div>
  );
}