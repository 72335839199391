import React from 'react';
import { X, CheckCircle, XCircle } from 'lucide-react';
import { UserProgress } from '../../types/nocodb';
import { getSectionInfo } from '../../utils/progressAnalyzer';
import { SOLUTION_SECTIONS } from '../../constants/sections';

interface SolutionModalProps {
  user: UserProgress;
  onClose: () => void;
}

export const SolutionModal: React.FC<SolutionModalProps> = ({ user, onClose }) => {
  const groupSectionsByProduct = (sections: string[]) => {
    const grouped: { [key: string]: string[] } = {};
    
    sections.forEach(sectionId => {
      const info = getSectionInfo(sectionId);
      if (info) {
        if (!grouped[info.solution]) {
          grouped[info.solution] = [];
        }
        grouped[info.solution].push(sectionId);
      }
    });
    
    return grouped;
  };

  const completedByProduct = groupSectionsByProduct(user.completedSections);
  const missingByProduct = groupSectionsByProduct(user.missingSections);

  const renderSections = (sectionIds: string[], solution: string) => {
    return sectionIds.map(id => {
      const sectionName = SOLUTION_SECTIONS[solution].sections[id as keyof typeof SOLUTION_SECTIONS[typeof solution]['sections']];
      return (
        <li key={id} className="text-sm text-gray-600 px-3 py-2 rounded-lg flex items-center justify-between">
          <span>{sectionName}</span>
          <span className="text-gray-400">({id})</span>
        </li>
      );
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl max-w-4xl w-full max-h-[80vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200 flex items-center justify-between">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Solution Progress Details</h2>
            <p className="text-sm text-gray-500">{user.frontegg_clientname}</p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>
        
        <div className="p-6 overflow-y-auto max-h-[calc(80vh-129px)]">
          <div className="mb-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="font-medium text-gray-900">Progress Overview</h3>
              <span className="text-sm font-medium text-blue-600">
                {user.solutions_progress}% Complete
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
                style={{ width: `${user.solutions_progress}%` }}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <h3 className="font-medium text-gray-900 mb-4 flex items-center">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                Completed Sections
              </h3>
              <div className="space-y-6">
                {Object.entries(completedByProduct).map(([solution, sections]) => (
                  <div key={solution} className="bg-green-50 p-4 rounded-lg">
                    <h4 className="font-medium text-green-800 mb-2">{solution}</h4>
                    <ul className="space-y-1">
                      {renderSections(sections, solution)}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h3 className="font-medium text-gray-900 mb-4 flex items-center">
                <XCircle className="w-5 h-5 text-red-500 mr-2" />
                Remaining Sections
              </h3>
              <div className="space-y-6">
                {Object.entries(missingByProduct).map(([solution, sections]) => (
                  <div key={solution} className="bg-red-50 p-4 rounded-lg">
                    <h4 className="font-medium text-red-800 mb-2">{solution}</h4>
                    <ul className="space-y-1">
                      {renderSections(sections, solution)}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};