import React, { useState } from 'react';
import { Navigation } from './components/Navigation';
import { Dashboard } from './components/Dashboard';
import { IntegrationsPage } from './components/IntegrationsPage/IntegrationsPage';
import { SolutionsPage } from './components/SolutionsPage/SolutionsPage';
import { DesktopPage } from './components/DesktopPage/DesktopPage';
import { FinancialPage } from './components/FinancialPage/FinancialPage';
import { FeedbackPage } from './components/FeedbackPage/FeedbackPage';

type Route = 'dashboard' | 'solutions' | 'desktop' | 'feedback' | 'integrations' | 'financial';

export const App: React.FC = () => {
  const [currentRoute, setCurrentRoute] = useState<Route>('dashboard');

  const renderRoute = () => {
    switch (currentRoute) {
      case 'dashboard':
        return <Dashboard />;
      case 'solutions':
        return <SolutionsPage />;
      case 'desktop':
        return <DesktopPage />;
      case 'feedback':
        return <FeedbackPage />;
      case 'integrations':
        return <IntegrationsPage />;
      case 'financial':
        return <FinancialPage />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Navigation currentRoute={currentRoute} onRouteChange={setCurrentRoute} />
      {renderRoute()}
    </div>
  );
}