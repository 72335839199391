import React, { useEffect, useState } from 'react';
import { MessageSquare } from 'lucide-react';
import { FeedbackList } from './FeedbackList';
import { FeedbackModal } from './FeedbackModal';
import { CompanyFilter } from '../CompanyFilter';
import { FeedbackSummary } from '../../types/feedback';
import { getGeneralFeedback, getSolutionFeedback, getDesktopFeedback, getUserProgress } from '../../services/nocodbApi';
import { UserProgress } from '../../types/nocodb';

export const FeedbackPage: React.FC = () => {
  const [feedback, setFeedback] = useState<FeedbackSummary[]>([]);
  const [users, setUsers] = useState<{ [key: string]: UserProgress }>({});
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [generalData, solutionData, desktopData, userData] = await Promise.all([
          getGeneralFeedback(),
          getSolutionFeedback(),
          getDesktopFeedback(),
          getUserProgress()
        ]);

        // Create users lookup
        const usersLookup = userData.reduce((acc, user) => {
          acc[user.frontegg_email] = user;
          return acc;
        }, {} as { [key: string]: UserProgress });
        setUsers(usersLookup);

        // Group all feedback by email
        const feedbackMap = new Map<string, FeedbackSummary>();

        // Process general feedback
        generalData.forEach(item => {
          if (!feedbackMap.has(item.Client)) {
            feedbackMap.set(item.Client, {
              email: item.Client,
              generalFeedback: [],
              solutionFeedback: [],
              desktopFeedback: []
            });
          }
          feedbackMap.get(item.Client)!.generalFeedback.push(item);
        });

        // Process solution feedback
        solutionData.forEach(item => {
          if (!feedbackMap.has(item.Client)) {
            feedbackMap.set(item.Client, {
              email: item.Client,
              generalFeedback: [],
              solutionFeedback: [],
              desktopFeedback: []
            });
          }
          feedbackMap.get(item.Client)!.solutionFeedback.push(item);
        });

        // Process desktop feedback
        desktopData.forEach(item => {
          const existingUsers = Array.from(feedbackMap.keys());
          existingUsers.forEach(email => {
            feedbackMap.get(email)!.desktopFeedback.push(item);
          });
        });

        setFeedback(Array.from(feedbackMap.values()));
      } catch (err) {
        setError('Failed to load feedback data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Get unique companies from user data
  const companies = [...new Set(feedback.map(f => {
    const user = users[f.email];
    return user?.frontegg_accountname || 'Unknown Company';
  }))].sort();

  const filteredFeedback = selectedCompany
    ? feedback.filter(f => {
        const user = users[f.email];
        return user?.frontegg_accountname === selectedCompany;
      })
    : feedback;

  return (
    <div className="flex-1 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">User Feedback</h1>
          <p className="text-gray-500">View detailed feedback across all platforms</p>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
          </div>
        ) : error ? (
          <div className="bg-red-50 text-red-700 p-4 rounded-lg">
            {error}
          </div>
        ) : (
          <>
            <CompanyFilter
              companies={companies}
              selectedCompany={selectedCompany}
              onSelectCompany={setSelectedCompany}
              totalUsers={filteredFeedback.length}
            />
            <FeedbackList
              feedback={filteredFeedback}
              users={users}
              onSelectEmail={setSelectedEmail}
            />
          </>
        )}

        {selectedEmail && (
          <FeedbackModal
            feedback={feedback.find(f => f.email === selectedEmail)!}
            onClose={() => setSelectedEmail(null)}
          />
        )}
      </div>
    </div>
  );
};