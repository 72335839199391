import { IntegrationFeedback } from '../services/nocodbApi';

// This constant can be easily updated when more integration fields are added
export const MAX_INTEGRATION_FIELDS = 10;

export interface IntegrationField {
  key: string;
  value: string;
}

export const getIntegrationFields = (feedback: IntegrationFeedback): IntegrationField[] => {
  const fields: IntegrationField[] = [];
  
  // Dynamically check all possible integration fields
  for (let i = 1; i <= MAX_INTEGRATION_FIELDS; i++) {
    const key = `Integration${i}` as keyof IntegrationFeedback;
    const value = feedback[key];
    
    if (value && typeof value === 'string' && value.trim() !== '') {
      fields.push({
        key: `Integration ${i}`,
        value: value.trim()
      });
    }
  }
  
  return fields;
};

export const countIntegrations = (feedbacks: IntegrationFeedback[]): number => {
  let count = 0;
  feedbacks.forEach(feedback => {
    for (let i = 1; i <= MAX_INTEGRATION_FIELDS; i++) {
      const key = `Integration${i}` as keyof IntegrationFeedback;
      if (feedback[key] && typeof feedback[key] === 'string' && feedback[key].trim() !== '') {
        count++;
      }
    }
  });
  return count;
};