import React, { useEffect, useState } from 'react';
import { Thermometer, Lightbulb, Building2, Users } from 'lucide-react';
import { GeneralFeedback } from '../types/nocodb';
import { getGeneralFeedback } from '../services/nocodbApi';

export function GeneralFeedbackWidget() {
  const [feedback, setFeedback] = useState<GeneralFeedback[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const data = await getGeneralFeedback();
        // Filter out null entries and ensure Rating is a number
        const validData = data.filter(item => 
          item !== null && 
          item.Rating !== null && 
          item.Rating !== undefined
        ).map(item => ({
          ...item,
          Rating: Number(item.Rating)
        }));
        setFeedback(validData);
      } catch (error) {
        console.error('Error fetching feedback:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFeedback();
  }, []);

  if (loading) {
    return (
      <div className="grid grid-cols-4 gap-6">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="bg-white rounded-xl p-6 shadow-sm animate-pulse">
            <div className="h-16 bg-gray-200 rounded-lg"></div>
          </div>
        ))}
      </div>
    );
  }

  // Calculate metrics
  const validRatings = feedback.filter(f => !isNaN(f.Rating));
  const averageRating = validRatings.length > 0
    ? (validRatings.reduce((acc, curr) => acc + curr.Rating, 0) / validRatings.length).toFixed(1)
    : '0.0';

  const uniqueVendors = new Set(feedback.map(f => f.Vendors).filter(Boolean));
  const generalMeetingRequests = feedback.filter(f => f.Meeting === 'Yes').length;
  const uniqueIdeas = feedback.filter(f => f.Ideas && f.Ideas.trim() !== '').length;

  return (
    <div className="grid grid-cols-4 gap-6">
      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-red-50 p-3 rounded-lg">
            <Thermometer className="w-6 h-6 text-red-500" />
          </div>
          <span className="text-xs font-medium text-gray-500">Overall Direction</span>
        </div>
        <div className="mt-2">
          <div className="text-2xl font-semibold">{averageRating}</div>
          <div className="text-sm text-gray-500">Average Rating (1-10)</div>
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-yellow-50 p-3 rounded-lg">
            <Lightbulb className="w-6 h-6 text-yellow-500" />
          </div>
          <span className="text-xs font-medium text-gray-500">New Ideas</span>
        </div>
        <div className="mt-2">
          <div className="text-2xl font-semibold">{uniqueIdeas}</div>
          <div className="text-sm text-gray-500">Ideas Submitted</div>
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-purple-50 p-3 rounded-lg">
            <Building2 className="w-6 h-6 text-purple-500" />
          </div>
          <span className="text-xs font-medium text-gray-500">Competitors</span>
        </div>
        <div className="mt-2">
          <div className="text-2xl font-semibold">{uniqueVendors.size}</div>
          <div className="text-sm text-gray-500">Unique Companies</div>
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-green-50 p-3 rounded-lg">
            <Users className="w-6 h-6 text-green-500" />
          </div>
          <span className="text-xs font-medium text-gray-500">General Follow-ups</span>
        </div>
        <div className="mt-2">
          <div className="text-2xl font-semibold">{generalMeetingRequests}</div>
          <div className="text-sm text-gray-500">Feedback Meeting Requests</div>
        </div>
      </div>
    </div>
  );
}