// Pricing configuration with different pricing models
export const PRICING = {
  // Solutions pricing (per engineer)
  solutions: {
    'Automation-as-a-Service': { price: 12000, type: 'annual' },
    'Expert Path': { price: 125, type: 'per-engineer' },
    'Master Fix Database': { price: 50, type: 'per-engineer' },
    'Onboarding Navigator': { price: 10000, type: 'annual' },
    'Outage Hub': { price: 50, type: 'per-engineer' },
    'Project Path': { price: 100, type: 'per-engineer' },
    'Robotic Process Automation': { price: 50, type: 'per-engineer' },
    'Workflow Orchestrator': { price: 10000, type: 'annual' }
  },
  
  // Desktop feature pricing (per PC)
  desktop: {
    'Knowledge': { price: 0.50, type: 'per-pc' },
    'Troubleshoot': { price: 1, type: 'per-pc' },
    'Library': { price: 0.25, type: 'per-pc' },
    'Tickets': { price: 0.75, type: 'per-pc' },
    'Status': { price: 0.50, type: 'per-pc' },
    'Search': { price: 0.25, type: 'per-pc' },
    'Onboarding': { price: 0.50, type: 'per-pc' }
  }
};

// Helper types
export type PricingType = 'per-engineer' | 'per-pc' | 'annual';

export interface PricingConfig {
  price: number;
  type: PricingType;
}