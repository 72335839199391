import React from 'react';
import { Building2 } from 'lucide-react';

interface CompanyFilterProps {
  companies: string[];
  selectedCompany: string | null;
  onSelectCompany: (company: string | null) => void;
  totalUsers: number;
}

export function CompanyFilter({ companies, selectedCompany, onSelectCompany, totalUsers }: CompanyFilterProps) {
  return (
    <div className="mb-6 flex items-center space-x-4">
      <div className="flex-1">
        <select
          className="w-64 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          value={selectedCompany || ''}
          onChange={(e) => onSelectCompany(e.target.value || null)}
        >
          <option value="">All Companies</option>
          {companies.map((company) => (
            <option key={company} value={company}>
              {company}
            </option>
          ))}
        </select>
      </div>
      
      <div className="flex items-center space-x-2 bg-blue-50 px-4 py-2 rounded-lg">
        <Building2 className="w-5 h-5 text-blue-500" />
        <span className="text-blue-700 font-medium">
          {selectedCompany ? `${totalUsers} Users` : `${companies.length} Companies, ${totalUsers} Users`}
        </span>
      </div>
    </div>
  );
}