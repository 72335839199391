import React from 'react';
import { TrendingUp, Users, DollarSign, Percent, Calendar } from 'lucide-react';
import { CompanyInterests } from '../../types/nocodb';
import { PRICING, PricingType } from '../../config/pricing';

interface RevenueProjectionsProps {
  companies: Array<{ name: string; interests: CompanyInterests }>;
}

interface RevenueMetrics {
  interestedCompanies: number;
  monthlyMRR: {
    perEngineer: number;
    perPC: number;
    total: number;
  };
  annualRevenue: number;
  totalARR: number;
  conversionRate: number;
}

export function RevenueProjections({ companies }: RevenueProjectionsProps) {
  const calculateMetrics = (): RevenueMetrics => {
    let perEngineerMRR = 0;
    let perPCMRR = 0;
    let annualRevenue = 0;
    let interestedCompanies = 0;

    companies.forEach(({ interests }) => {
      const hasInterest = 
        (interests.solutions && interests.solutions.length > 0) || 
        (interests.desktop && interests.desktop.length > 0);
      
      if (hasInterest) {
        interestedCompanies++;
      }

      // Calculate Solutions Revenue
      if (interests.solutions) {
        interests.solutions.split(',').forEach(solution => {
          const [name, count] = solution.split(':');
          const licenses = parseInt(count || '0', 10);
          const pricing = PRICING.solutions[name as keyof typeof PRICING.solutions];
          
          if (pricing) {
            switch (pricing.type) {
              case 'per-engineer':
                perEngineerMRR += licenses * pricing.price;
                break;
              case 'annual':
                annualRevenue += licenses * pricing.price;
                break;
            }
          }
        });
      }

      // Calculate Desktop Revenue
      if (interests.desktop) {
        interests.desktop.split(',').forEach(feature => {
          const [name, count] = feature.split(':');
          const licenses = parseInt(count || '0', 10);
          const pricing = PRICING.desktop[name as keyof typeof PRICING.desktop];
          
          if (pricing && pricing.type === 'per-pc') {
            perPCMRR += licenses * pricing.price;
          }
        });
      }
    });

    const totalMRR = perEngineerMRR + perPCMRR;
    const totalARR = (totalMRR * 12) + annualRevenue;
    const conversionRate = companies.length > 0 
      ? Math.round((interestedCompanies / companies.length) * 100) 
      : 0;

    return {
      interestedCompanies,
      monthlyMRR: {
        perEngineer: perEngineerMRR,
        perPC: perPCMRR,
        total: totalMRR
      },
      annualRevenue,
      totalARR,
      conversionRate
    };
  };

  const metrics = calculateMetrics();
  const formatCurrency = (amount: number) => 
    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-semibold flex items-center space-x-2">
        <TrendingUp className="w-5 h-5 text-blue-500" />
        <span>Revenue Projections</span>
      </h2>

      <div className="grid grid-cols-6 gap-6">
        <div className="bg-white rounded-xl p-6 shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <div className="bg-blue-50 p-3 rounded-lg">
              <Users className="w-6 h-6 text-blue-500" />
            </div>
            <span className="text-xs font-medium text-gray-500">Interest</span>
          </div>
          <div className="mt-2">
            <div className="text-2xl font-semibold">{metrics.interestedCompanies}</div>
            <div className="text-sm text-gray-500">Companies</div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <div className="bg-green-50 p-3 rounded-lg">
              <DollarSign className="w-6 h-6 text-green-500" />
            </div>
            <span className="text-xs font-medium text-gray-500">Solutions MRR</span>
          </div>
          <div className="mt-2">
            <div className="text-2xl font-semibold">{formatCurrency(metrics.monthlyMRR.perEngineer)}</div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <div className="bg-indigo-50 p-3 rounded-lg">
              <DollarSign className="w-6 h-6 text-indigo-500" />
            </div>
            <span className="text-xs font-medium text-gray-500">Desktop MRR</span>
          </div>
          <div className="mt-2">
            <div className="text-2xl font-semibold">{formatCurrency(metrics.monthlyMRR.perPC)}</div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <div className="bg-yellow-50 p-3 rounded-lg">
              <Calendar className="w-6 h-6 text-yellow-500" />
            </div>
            <span className="text-xs font-medium text-gray-500">Annual Package</span>
          </div>
          <div className="mt-2">
            <div className="text-2xl font-semibold">{formatCurrency(metrics.annualRevenue)}</div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <div className="bg-orange-50 p-3 rounded-lg">
              <DollarSign className="w-6 h-6 text-orange-500" />
            </div>
            <span className="text-xs font-medium text-gray-500">Total ARR</span>
          </div>
          <div className="mt-2">
            <div className="text-2xl font-semibold">{formatCurrency(metrics.totalARR)}</div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <div className="bg-purple-50 p-3 rounded-lg">
              <Percent className="w-6 h-6 text-purple-500" />
            </div>
            <span className="text-xs font-medium text-gray-500">Conversion</span>
          </div>
          <div className="mt-2">
            <div className="text-2xl font-semibold">{metrics.conversionRate}%</div>
            <div className="text-sm text-gray-500">Showing Interest</div>
          </div>
        </div>
      </div>
    </div>
  );
}