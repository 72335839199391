import React from 'react';
import { Users, DollarSign } from 'lucide-react';
import { CompanyInterests } from '../../types/nocodb';
import { PRICING } from '../../config/pricing';

interface ProductMatrixProps {
  companies: Array<{ name: string; interests: CompanyInterests }>;
}

export function ProductMatrix({ companies }: ProductMatrixProps) {
  const calculateMetrics = (productName: string, type: 'solutions' | 'desktop') => {
    const interestedClients = companies.filter(({ interests }) => {
      const items = type === 'solutions' ? interests.solutions : interests.desktop;
      return items?.split(',').some(item => item.startsWith(productName + ':'));
    });

    const totalLicenses = interestedClients.reduce((sum, { interests }) => {
      const items = type === 'solutions' ? interests.solutions : interests.desktop;
      const item = items?.split(',').find(i => i.startsWith(productName + ':'));
      if (item) {
        const [, count] = item.split(':');
        return sum + (parseInt(count) || 0);
      }
      return sum;
    }, 0);

    return {
      clientCount: interestedClients.length,
      licenseCount: totalLicenses
    };
  };

  const formatPrice = (price: number, type: string) => {
    const formatter = new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: 'USD',
      minimumFractionDigits: type === 'per-pc' ? 2 : 0
    });
    return `${formatter.format(price)}${type === 'annual' ? '/year' : type === 'per-pc' ? '/pc' : '/user'}`;
  };

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Product Interest</h2>
      </div>
      
      <div className="divide-y divide-gray-200">
        {/* Solutions */}
        {Object.entries(PRICING.solutions).map(([solution, pricing]) => {
          const { clientCount, licenseCount } = calculateMetrics(solution, 'solutions');
          return (
            <div key={solution} className="px-6 py-4 flex items-center justify-between">
              <div>
                <div className="text-sm font-medium text-gray-900">{solution}</div>
                <div className="flex items-center space-x-2 text-sm text-gray-500">
                  <span>Solution</span>
                  <span>•</span>
                  <div className="flex items-center text-gray-400">
                    <DollarSign className="w-3 h-3 mr-1" />
                    <span>{formatPrice(pricing.price, pricing.type)}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <Users className="w-4 h-4 text-gray-400" />
                <span className="text-sm text-gray-900">
                  {clientCount} clients, {licenseCount} licenses
                </span>
              </div>
            </div>
          );
        })}

        {/* Desktop Features */}
        {Object.entries(PRICING.desktop).map(([feature, pricing]) => {
          const { clientCount, licenseCount } = calculateMetrics(feature, 'desktop');
          return (
            <div key={feature} className="px-6 py-4 flex items-center justify-between">
              <div>
                <div className="text-sm font-medium text-gray-900">{feature}</div>
                <div className="flex items-center space-x-2 text-sm text-gray-500">
                  <span>Desktop Feature</span>
                  <span>•</span>
                  <div className="flex items-center text-gray-400">
                    <DollarSign className="w-3 h-3 mr-1" />
                    <span>{formatPrice(pricing.price, pricing.type)}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <Users className="w-4 h-4 text-gray-400" />
                <span className="text-sm text-gray-900">
                  {clientCount} clients, {licenseCount} licenses
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}